.catpchaSet{
	.help{margin-bottom:5px;}
	.set{
		.l{float:left;
			#catpcha{float:left;margin-right:4px;}
			.b{float:left;
				margin-right:4px;
				button{
					min-width:30px;
					min-height:24px;
					display:block;
					background-color:#fff;
					border:1px solid #ccc;
					border-right:3;
					&+button{margin-top:2px;}
				}
			}
			#reLoad{}
			#soundOnKor{}
		}
		#catpchaAnswer{float:left;height:50px;line-height:48px;padding:0 10px;font-size:18px;width:130px;
			border:1px solid #ccc;
		}
	}
}