.photoList {border-top:1px solid #e6e6e6;padding:5px 0}
.photoList li{overflow:hidden;padding:20px 0;border-bottom:1px solid #e6e6e6;position:relative}
.photoList li .img{float:left;margin-right:20px}
.photoList li dl {overflow:hidden}
.photoList li dl dt{font-size:18px;color:#494949;line-height:139%;font-weight: bold;}
.photoList li dl dt span.date{font-size:12px;padding:0;color:#737373;display:block;font-weight:normal;}
.photoList li dl dd{padding:8px 0;}
.photoListTypeText{}
.photoListTypeText ul{overflow:hidden;}
.photoListTypeText dd{display:none;}
.photoListTypeText li{width:33%;overflow:hidden;padding:10px;border-bottom:1px solid #e6e6e6;position:relative;float:left;}
.photoListTypeText li .img{float:none;margin-right:0;width:100%;}
.photoListTypeText li dl {width:100%;}
.photoListTypeText li dl dt{height:50px;font-size:18px;color:#494949;line-height:139%;font-weight: bold;
	display:block;
	span{
		text-overflow:ellipsis;
		white-space:nowrap;
		overflow:hidden;
		display:block;
	}
}
.photoListTypeText li dl dt span.date{font-size:12px;padding:0;color:#737373;display:block;font-weight:normal;}
.photoListTypeText li dl dd{padding:8px 0;}

.sh .bbsSchArea{display:inline-block;padding:0;float:right}
.sh .inpSel {width: 120px;}
.sh .searchWrd{width:200px; padding: 0 5px}

.photoListTop{overflow:hidden;margin-bottom:10px;}
.photoListTop form{float:right;}
.photoListTop>*{vertical-align:top;margin:0;}
.photoListTop .sh{margin:0;}
.photoType{float:left;}
.photoType button{float:left;background:#cccccc url(/static/ucms/img/galleryType.png) 0 0 no-repeat;width:28px;height:28px;margin-right:4px;border:none;}
.photoType button.imgType{}
.photoType button.textType{background-position:right 0;}
.photoType button.active{background-color:#feb424;}


.sliderMc{position:relative;z-index:10;width:100%;height:410px;margin:20px 0;overflow:hidden;padding:20px;
	&::after {
		content: '';
		display:block;
		clear:both;
	}
}
.sliderMc .thumbs{position:relative;z-index:10;width:590px;height:533px;float:left; overflow:hidden;}
.sliderMc .thumbs>div{position:relative;z-index:10;width:596px;height:533px;overflow:hidden;float:left;}
.sliderMc .thumbs>div a{display:block;padding:5px;border:2px solid #cacaca;}
.sliderMc .thumbs>div a img{width:100%;}

.sliderMc .descSet{position:absolute;left:0;top:0;width:210px;height:304px;padding:38px 20px; background:url(../images/mains/opacity60.png);}
.sliderMc .descSet a, .sliderMc .descSet h2, .sliderMc .descSet p, .sliderMc .descSet span{color:#fff; font-size:12px;line-height:18px;}
.sliderMc .descSet .stitle{font-size:15px;line-height:20px; margin-top:8px;margin-bottom:20px}
.sliderMc .descSet h2{display:inline-block;line-height:20px;background:#ed1c24; padding:0 5px;}

.thumbsList{width:110px;height:470px;overflow:hidden;margin-top:16px;float:right;}
.thumbsList li{display:inline-block;float:left;margin-bottom:9px;overflow:hidden;width:100%;height:80px;margin-bottom:10px;}
.thumbsList li img{width:100%;vertical-align:top;height:70px;}
.thumbsList li a{border:3px solid #fff;display:block;overflow:hidden;}
.thumbsList li a:hover, 
.thumbsList li a:focus, 
.thumbsList li a:active, 
.thumbsList li.on a{border-color:#febe10;}
.sliderMcCtrl .prevVs{position:absolute;z-index:50;top:0; right:20px; background:url(/static/ucms/img/photoCtrlUp.png) no-repeat;width:111px;height: 23px;}
.sliderMcCtrl .nextVs{position:absolute;z-index:50;bottom:0; right:20px; background:url(/static/ucms/img/photoCtrlDown.png) no-repeat; width:111px;height: 23px;}