/* TABLES */
.table{
	width:100%;
	border:none;border-top:3px solid #434040;text-align:center;margin-top:10px;
	th{text-align:center;padding:8px 0;}
}

.table thead th{position:relative;}
.table thead th::after {
	display:none;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-color: #E5E5E5;
  border-width: 0;
}
.table > thead > tr > th{
	background: #fff;
	color: #333333;
	font-size: 16px;
	border-bottom:1px solid #434040;vertical-align:middle;line-height:1.2;
	background: -moz-linear-gradient(#FFFFFF 42%, #E4E4E4 100%);/* FF3.6+ */
	background: -webkit-gradient(linear, 270deg, color-stop(42%, FFFFFF), color-stop(100%, E4E4E4));/* Chrome,Safari4+ */
	background: -webkit-linear-gradient(#FFFFFF 42%, #E4E4E4 100%);/* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(#FFFFFF 42%, #E4E4E4 100%);/* Opera 11.10+ */
	background: -ms-linear-gradient(#FFFFFF 42%, #E4E4E4 100%);/* IE10+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1301FE', endColorstr='#F4F60C', GradientType='1'); /* for IE */
	background: linear-gradient(180deg, #FFFFFF 42%, #E4E4E4 100%);/* W3C */	
}
.table > tbody > tr > td{border:none;border-bottom:1px solid #cccccc;}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background: #fafafa;
}
.table-striped > tbody > tr:nth-child(odd):hover > td, 
.table-striped > tbody > tr:nth-child(odd):hover > th,
.table-hover > tbody > tr:hover{background-color:#ffffe4;}

.table > thead > tr > th {

}
.table>tbody>tr>td.img{padding:5px;}
.table>tbody>tr>td.img div{max-height: 150px;overflow:hidden;margin:0;}
.table>tbody>tr>td.img div img{max-width:150px;}
table.table .tit{padding: 0!important}
table.table .tit a{display: block;text-align: left;position: relative;padding: 11px 5px 12px 5px;}
table.table .tit a::before{
	content: '';
	border-top: 4px solid #ddd;
	border-left: 4px solid #9f9f9f;
	border-right: 4px solid transparent;
	border-bottom: 4px solid transparent;
	display: inline-block;
	position: absolute;
	left:0;top:0;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display:none;
}
.table tr.disabled td{background-color:#eee;cursor: not-allowed;}
/**/
table.sm tr th{font-weight:normal;padding:2px}
table.sm tr td{font-weight:normal;padding-left:2px;padding-right: 2px;}

table.tac th,
table.tac td{text-align:center;}
tr.tac>*{text-align:center;}

td .progress{margin-bottom:0;}

.ico-reply-ing{background-color:#707070;border:1px solid #707070;border-radius:3px;display:inline-block;padding:6px 8px;line-height:1;color:#fff;}
.ico-reply-end{background-color:#df8800;border:1px solid #df7106;border-radius:3px;display:inline-block;padding:6px 8px;line-height:1;color:#fff;}

.dbData{padding:20px;}