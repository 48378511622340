[class^=b-]{
	color:#cdcdcd;
	display:inline-block;
	min-width:88px;
	text-align:center;
	padding:8px 0 7px;
	font-size:15px;
	// border:1px solid #cdcdcd;
	color:#fff;
	// background:#fff url(/static/ucms/img/joinArr.png) 109px center no-repeat;
	background-color:#163d40;
	border-radius:0;
	vertical-align:middle;
	border:none;
	line-height:1;
	&.b-reg,
	&.b-list{
		padding:16px 42px;
		background-color:#163d40;
		font-size:18px;
		font-weight:bold;
		&:before {
			content: '';
			display:inline-block;
			width:35px;
			height:14px;
			background: url(/static/ucms/img/btn-list.png) 0 0 no-repeat;
		}
	}
	&.b-join{
		@extend .b-lg;
		background-color:#fff;
		color:#163d40;
		background: url(/static/ucms/img/ico-arrRight.png) 152px center no-repeat;
		width:180px;
		border:1px solid #000;
		text-align:left;
		height:50px;

	}
	&.b-lg{
		padding:16px 35px;
		font-size:18px;
		font-weight:bold;
		font-family:'Nanum Square';
	}
	&.b-arr{
		margin-left:10px;
		border:1px solid #000;
		padding:15px 67px 15px 20px;
		line-height:1;
		background:#fff ;
		color:#141414;
		position:relative;
		&:after {
			content: '';
			width:8px;
			height:14px;
			background: url('/static/ucms/img/ico-arrRight.png') right center no-repeat;
			position:absolute;
			right:20px;
			top:0;
			bottom:0;
			margin: auto;
			
		}
	}
}
[class^=b-]:hover,
[class^=b-]:focus{
	// color:#df6900;
	// border-color:#df6900;
	text-decoration:underline;
	// color:#000;
}
.b-del,
.b-submit,
.b-total,
.b-save,
.b-sh,
.b-edit,
.b-cancel{
	color:#fff;
}
.b-submit,
.b-total,
.b-save,
.b-sh,
.b-edit,
.b-cancel,
.b-list:hover{
	// color:#fff;
}
.b-del,
.b-save,
.b-edit{
	background-color:#707070;
	border-color:#707070;
}
.url-link{
	margin:20px 0;
	background:#e7ebec url(/static/ucms/img/ico-doc.png) 20px center no-repeat;
	line-height:1;
	padding:12px 50px 12px 45px;
	color:#163d40;
	font-size:16px;
	font-weight:bold;
	display:inline-block;
	border-radius:5px;
	box-shadow: 1px 2px 0 0 hsla(0,0%,0%,.25);
	position:relative;
	&:after {
		content: '';
		position:absolute;
		right:20px;
		top:13px;
		width:8px;
		height:14px;
		background: url(/static/ucms/img/ico-arrRight.png) 0 0 no-repeat;
	}
}