$bdcolor : #ccc;
/* 셀렉트 */
input[type=text]::-ms-clear{display:none;}
input{vertical-align:middle;}
select{
	padding:5px 8px;
	color:#666;
	box-sizing:border-box;
	border:1px solid $bdcolor;
	border-radius:0;
	// -webkit-appearance: none;
	min-height:28px;
	font-family:'Nanum Square';
	line-height:1;
	vertical-align:middle;
}
/* input */
input[type="email"],
input[type="number"],
input[type="text"],
input[type="password"]{
	color:#666;
	border:1px solid $bdcolor;
	border-radius:0;
	font-size:15px;
	font-family:'Nanum Square';
	line-height:1;
	height:30px;
	text-indent:10px;
}
input[type="password"]{font-family:'맑은 고딕'} //폰트설정시 패스워드가 특수기호라서 글자가 나오지 않음 
textarea{
	border:1px solid $bdcolor;
	border-radius:0;
	height:247px;
}
.tel{
	width:80px;
}

// input[type=radio]{
// 	position: absolute;
// 	opacity:0;
// 	z-index:1;
// 	width:14px;
// 	height:14px;
// 	cursor:pointer;
// 	&~i{
// 		position:relative;
// 		display:inline-block;
// 		vertical-align:middle;
// 		border:1px solid #cdcdcd;
// 		border-radius: 100%;
// 		height: 14px;
// 		width: 14px;
// 		transition: border .25s linear;
// 		background-color:#f6f6f6;
// 		margin-right:5px;
// 		&::before {
// 			display: block;
// 			position: absolute;
// 			content: '';
// 			border-radius: 100%;
// 			height: 6px;
// 			width: 6px;
// 			top: 3px;
// 			left: 3px;
// 			margin: auto;
// 			transition: background 0.25s linear;
// 			}
// 	}
// 	&:checked ~ i{
// 		&::before {
// 			background-color:#f7941d;
// 			}
// 	}
// 	&:focus~i{
// 		border:1px dotted;
// 	}
// }
/*
input[type=checkbox]{
	// position: absolute;
	opacity:0;
	z-index:1;
	width:14px;
	height:14px;
	cursor:pointer;
	vertical-align:middle;
	&~i{
		position:relative;
		display:inline-block;
		vertical-align:middle;
		margin-left:-14px;
		border:1px solid #cdcdcd;
		height: 14px;
		width: 14px;
		background-color:#f6f6f6;
		margin-right:5px;
		&::before {
			display: block;
			position: absolute;
			content: '';
			left:2px;
			top:3px;
			}
	}
	&:checked ~ i{
		&::before {
			// @include sprite($ico-check);
			content: "\f14a";
			font:normal normal normal 14px/1 FontAwesome;
			}
	}
	&:focus~i{
		border:1px dotted;
	}
}
*/

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]
{
  -moz-appearance: textfield;
}