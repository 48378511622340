.join2{
	position:relative;
	overflow:hidden;
	background: url(/static/ucms/img/join-step.png) 0 0 no-repeat;
	@include breakpoint($tb){ 
		height: 40px;
		margin-bottom: 20px;
		background-image: none;
		background-color: #eee;
		.sr-only{
			clip:auto;
			width: 100%;
			height: 60px;
			font-size: 16px;
			padding: 5px;
			li{
				border:1px solid #eee;
				background-color: #fff;
				width: 25%;
				height: 52px;
				padding-top: 10px;
				float: left;
				text-align: center;
				position: relative;

				&.active{
					background-color: #ffa900;
				}
				&.active:after{
					content:'';
					position: absolute;
					z-index: 1;
					right: -15px;
					top: 0;
			    width:0; 
			    height:0; 
			    border-left:15px solid #ffa900;
			    border-top:24px solid transparent;
			    border-bottom:24px solid transparent; 
					@include breakpoint($sm){ 
				    border-top:24px solid transparent;
				    border-bottom:24px solid transparent; 
					}
				}
			}

			@include breakpoint($sm){ 
				font-size: 11px;
			}
		}
	}
	
	height:80px;
	margin-bottom:40px;
	@include breakpoint($tb){
		height: 60px; 
	}
	&.joinAgre{}
	&.joinVerify{background-position:0 -81px;}
	&.joinInput{background-position:0 -162px;}
	&.joinFinish{background-position:0 bottom;}
}
.agg-set{
	margin: 0 0 10px;
	padding: 9px 12px;
	border:1px solid #e1e1e1;
	background-color: #fcfcfc;
	.set{
		height:242px;
		overflow:scroll;
		overflow-x:hidden;
		padding-left: 12px;
		h2,
		h3,
		h4{
			font-size:1.2em;
			background-image:none;
			padding:0;
			margin:10px 0 5px;
			color:inherit;
			&::before {display:none}
		}
		em,strong,p{font-size:1em; line-height: 1.6em;}
	}
}
.jointext{
  	font-family: "NanumSquare";
  	margin-bottom:27px;
  .t{
  	font-size: 24px;
  	color: rgb(75, 87, 159);
  	font-weight: bold;
  	line-height: 1.5;
  }
  .t+p{
  	font-size:18px;
  }
  &.type2{
  	text-align:center;
  	.t+p{
  		font-size:18px;
  	}
  }
  &.type3{
  	.t+p{
  		font-size:18px;
  	}
  }
}
.aggtotal{
	text-align:center;
	label{
		font-size:14px;
		font-weight:bold !important;
	}
}
.certify{
	font-family:'Nanum Square';
	.set{
		background:#fff url(/static/ucms/img/patten.png);
		border:4px solid #d0d8d9;
		padding:30px 0 35px;
		text-align:center;
	}
	h3{
		font-size:22px;font-weight:bold;
		line-height:1;
		position:relative;
		margin:12px;
		&:before {
			content: '';
			height:96px;
			display:block;
			background: url(/static/ucms/img/ico-hp.png) center 0 no-repeat;
			margin-bottom:41px;
		}
		&:after {
			content: '';
			position:absolute;
			width:60px;
			height:2px;
			background-color:#163d40;
			left:0;
			right:0;
			bottom:40px;
			margin: 0 auto;
		}
	}
	p{
		font-size:18px;margin-bottom:25px; word-break: keep-all;
		@include breakpoint($sm){ font-size: 14px; padding:0 10%; }
	}
	a{background-color:#feb424 !important; color: #141414;}
}
.snsLogin{
	background:#fff url(/static/ucms/img/patten.png);
	border:4px solid #d0d8d9;
	margin:20px 0;
	padding:60px 0;
	text-align:center;
	h3{
		font-size:22px;
		font-family:'Nanum Square';
		font-weight:bold;
		position:relative;
		margin-bottom:34px;
		&:after {
			content: '';
			position:absolute;
			width:60px;
			height:2px;
			background-color:#163d40;
			left:0;
			right:0;
			bottom:40px;
			margin: 0 auto;
		}
	}
	ul{
		margin: 0 auto;
		display:table;
		table-layout:fixed;
		text-align: center;
		@include breakpoint($tb){ width: 100%; }
	}
	li{
		display:table-cell;
		vertical-align:middle;
		padding:0 50px;
		@include breakpoint($tb){ padding:0; width: 20%; }
		@include breakpoint($xs){ display: inline-block;	width: 33.3333%; }
	}
	a{
		display:block;
		margin:0 auto;
		width:70px;
		font-size:18px;
		line-height:1;
		font-weight:bold;
		font-family:'Nanum Square';
		&:before {
			content: '';
			display:block;
			height:70px;
			background: url(/static/ucms/img/ico-google.png) center 0 no-repeat;
			margin-bottom:20px;
		}
		&#facebook:before{background-image:url(/static/ucms/img/ico-facebook.png);}
		&#naver:before{background-image:url(/static/ucms/img/ico-naver.png);}
		&#kakao:before{background-image:url(/static/ucms/img/ico-kakao.png);}
		&#twitter:before{background-image:url(/static/ucms/img/ico-twitter.png);}
	}
}
.certifyInfor{
	background: url(/static/ucms/img/ico-certifyInfor.png) 0 10px no-repeat;
	margin:30px 0;
	padding-left:116px;
	height:108px;
	h3{
		font-size:18px;
		font-family:'Nanum Square';
		font-weight:bold;
		margin-bottom:15px;
	}
	ul{}
	li{
		margin-top:8px;
		padding-left:12px;
		text-indent:-12px;
		&:before {
			content: '- ';
			display:inline;
		}
	}
	&.i2{
		background-image:url(/static/ucms/img/ico-join4.png);
	}
}

.moreLink{
	font-size:18px;
	font-family:'Nanum Square';
	font-weight:bold;
	line-height:50px;
	.certifyInfor+&{border-top:1px solid $bdcolor;padding-top:30px;}
	> div{
		overflow:hidden;
		margin-bottom:10px;
		> p{
			float:left;
			&:before {
				content: '';
				display:inline-block;
				margin-right:10px;
				width:4px;
				height:4px;
				background-color:#163d40;
				vertical-align:middle;				
			}

		}
		.r{
			float:right;
		}
		a{
			float:left;
			margin-left:10px;
			border:1px solid #000;
			padding:16px 20px;
			width:180px;
			line-height:1;
			background: url(/static/ucms/img/ico-arrRight.png) 152px center no-repeat;
		}
	}
}