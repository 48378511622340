.view{
	border-top:2px solid $bdtopcolor;
	border-bottom:1px solid $bdtopcolor;
	display:table;
	width:100%;
	table-layout:fixed;
	margin-bottom:10px;
	padding-bottom:10px;
	margin:40px 0;
	position:relative;
	font-size:15px;
	@include breakpoint(768px){
		padding:0;
	}
	.subject{
		border-bottom:1px solid $bdtopcolor;
		padding:10px 0;
		position:relative;
		font-size:20px;
		font-weight:bold;
		text-align:center;
		font-family:'Nanum Square';
		@include breakpoint($md){
			padding:14px 10px;
		}
		button,
		span{
			position:absolute;
			right:0;
			top:15px;
			padding:5px 15px;
			@include breakpoint($md){
				right:30px;
			}
			&.status-ing{
				border:1px solid #ff7f00;
				color:#ff7f00;
			}
			&.status-end{
				border:1px solid #000;
				color:#000;
			}
			&.url-copy{
				border:1px solid #000;
				color:#000;
			}
		}
	}
	.fav{
		position:absolute;
		right:0;
		top:0;
	}
	dl>*{
		display:block;
		border:none;
	}
	@include breakpoint(max-width 767px){
		dl{
			clear:both;
			overflow:hidden;
			dt,dd{
				float:left;
				border-top:1px solid $bdcolor;
				padding:15px 0;
				line-height:1.3;
			}
			dt{
				width:20%;
				clear:both;
			}
			dd{
				padding-left:10px;
				width:80%;
			}
		}
		.subject+dl>dt:first-child,
		.subject+dl>dt:first-child+dd{
			border-top:none;
		}
		dl.long{
			border-top:1px solid $bdcolor;
			>*{
				width:auto;
				display:inline-block;
				border:none;
			}
			dt{padding-right:10px;}
		}
	}
	@include breakpoint($md){
		dl{
			display:table;
			width:100%;
			table-layout:fixed;
			>*{
				display:table-cell;
				float:none;
				vertical-align:middle;
				border-bottom:1px solid $bdcolor;
			}
		}
	}
	dl{
		dt{
			position:relative;
			color:#222;
			&:before {
				content: '';
				position:absolute;
				right:0;
				top:50%;
				margin-top:-8px;
				background-color:#e6e6e6;
				width:1px;
				height:16px;
			}
			@include breakpoint($md){
				border:none;
				width:113px;
				border-bottom:1px solid $bdcolor;
				text-align:center;
				padding-top:14px;
				padding-bottom:14px;
				@include breakpoint($lg){
					width:120px;
				}

			}
		}
		dd+dt:after {
			content: '';
			position:absolute;
			left:0;
			top:50%;
			margin-top:-8px;
			background-color:#e6e6e6;
			width:1px;
			height:16px;
		}
		dd{
			p{line-height:1.5;}
			input[type=text]{
				&.w100p{width:100%;}
				&.post{width:40%;}
				&.emailId{width:46%;}
				&.emailAddr{width:46%;}
			}
			input[type=password]{width:100%;}
			@include breakpoint($md){
				padding:14px 20px;
				input[type=text]{
					&.post{width:60px;text-align:center;text-indent:0;}
					&.emailId{width:105px}
					&.emailAddr{width:105px}
				}
				input[type=password]{}
			}
		}
		&:last-child > *{border-bottom:none;}
	}
	label{
		margin-right:20px;
		white-space:nowrap;
	}
	textarea{width:100%;}
	.labelw{
		label{
			display:block;
			margin-right:0;
			input{
				width:100%;
				margin-top:5px;
			}
		}
	}
	.inputhelp{
		display:inline;
		@include breakpoint($sm){
			display:ruby-base;
		}
	}
	@include breakpoint($md){
		&.w{
			dt{width:145px;}
		}
		dl.long{
			dt{
				width:21%;
			}
		}
	}
}
.view-bd{
	>dl>dt{
		border-right:1px solid $bdcolor;
		&:before {
			display:none;
		}
		@include breakpoint($md){
			width:139px;
		}
	}
}
.view-img{
	.img{margin:30px 0;}
	.img img{
		max-width:100%;
	}
	.img:empty{
		padding-top:76%;
		background:#e6e6e6 url(/static/kspo/img/sub/logogray.png) center center no-repeat;
		background-size:173px auto;
	}
	@include breakpoint($md){
		.img{margin:0}
		.both{
			display:table;
			width:100%;
			table-layout:fixed;
			border-bottom:1px solid $bdcolor;
			>*{
				display:table-cell;
				vertical-align:top;
			}
		}
		.left{
			width:50%;
			padding:30px;
			vertical-align:middle;
			img{max-width:100%;}
		}
		.right{
		}
	}
}
.dbData{
	border-bottom:1px solid #707686;
	padding:30px 0;
	.bu1+&{border-top:1px solid $bdcolor;}
	@include breakpoint($md){
		padding:33px-15 60px;
	}
	img{
		max-width:100%;
	}
	&.viewResult{
		background-color:#f3f5f5;
	}
}
.dbimg-list{
	text-align:center;
	img{
		max-width:100%;
		margin-bottom:20px;
	}
}
.dbData2{
	padding:30px;
	border:1px solid #707686;
	border-width:1px 0;
	margin-top:20px;
	img{
		max-width:100%;
	}
}
.dbData3{
	padding:20px 0;
	img{
		max-width:100%;
	}
}
.btnSet{margin:15px 0;
	&+h2.bu1{margin-top:70px;}
	@include breakpoint($md){
		margin:30px 0;
		.paginationSet+&{
			margin-top:-36px;
		}
	}
}
.table{
	overflow:hidden;
	position:relative;
	&:after {
		content: '';
		position:absolute;
		right:0;
		height:100%;
		top:0;
		right:0px;
		border-right:2px solid #fff;
	}
	table{
		margin-left:-1px;
		margin-right:-1px;
		width:100%;
		border-bottom:1px solid #707685;
		table-layout:fixed;
		table th,
		table td{
			border:1px solid $bdcolor;
		}
		th{
			font-size:15px;font-weight:normal;color:#222;padding:12px 0;border-top:1px solid #707685;font-family:'Noto-Regular';
		}
		td{
			font-size:15px;
			text-align:center;font-family: "Noto-Light", sans-serif;
			color:#656565;
			padding:10px 0;
			border-left:1px solid #e6e6e6;
			border-top:1px solid #e6e6e6;
			&.tal{
				padding-left:20px;
			}
		}
		tbody{
			th{font-family: "Noto-Light", sans-serif;border-top:1px solid #e6e6e6;}
			tr>td:first-child{
				// border-left:none;
			}
			th,
			td{
				border:1px solid $bdcolor;
			}
			.foot{
				th,
				td{
					background-color:#f8f8f9;
					color:#0a2a70;
					font-family:'Noto-Regular';
					text-align:center !important;
				}
			}
		}
	}
	ul{
		text-align:left;
		padding-left:10px;
		padding-right:10px;
		li{}
	}
	&.bd{
		table th,
		table td{
			border:1px solid $bdcolor !important;
		}
		table tbody tr:first-child>*{
			border-top:none !important;
		}
	}
	&.bd2{
		table th,
		table td{
			// border:1px solid $bdcolor !important;
		}
		table thead th{
			border-bottom:1px solid #cccccc !important;
			&+th{
				border-left:1px solid $bdcolor;
			}
		}
		table tbody th{
			color:#222;
		}
		table tbody tr:first-child>*{
			border-top:none !important;
		}
	}
	&.td-tal{
		table td{text-align:left;padding-left:20px;}
	}
	&.type2{
		table thead th{
			border-left:1px solid $bdcolor;
			border-bottom:1px solid $bdcolor;
			text-align:center;
			// &:first-child{border-left:none;}
		}
		table td{
			text-align:left;
		}
		table tbody th{text-align:center;}
		// table tbody tr:last-child th{border-bottom:1px solid #707685;}
		table tbody td{padding-left:15px;padding-right:15px;}
		table tbody tr:first-child>*{border-top:none;}
	}
	&.tac{
		table td{
			text-align:center;
		}
	}
	&.type3{
		table th{
			border-left:1px solid $bdcolor;
			border-bottom:1px solid $bdcolor;
			text-align:center;
			&:first-child{border-left:none;}
		}
		table td{
			text-align:center;
		}
		table tbody th{text-align:left;padding-left:20px;}
		table tbody tr:last-child th{border-bottom:1px solid #707685;}
		table tbody td{
			padding-left:15px;padding-right:15px;
		}
		table tbody tr:last-child th,
		table tbody tr:last-child td,
		table tbody tr td.bd-b{
			// border-bottom:1px solid #707685;
		}
		table tbody tr:first-child>*{border-top:none;}
		margin-bottom:-1px;
		border-bottom:1px solid #707685;
	}
	&.s{
		table tbody td{padding:5px 3px;}
	}
	&.w{
		overflow-x:auto;
		table{
			min-width:600px;
		}
		@include breakpoint($lg){
			overflow-x:hidden;
		}
	}
	&.w2{
		overflow-x:auto;
		table{
			min-width:750px;
			tbody td{
				padding-left:4px !important;
				padding-right:4px !important;
			}
		}
		@include breakpoint($lg){
			overflow-x:hidden;
		}
	}
	&.ma{
		@include breakpoint(max-width 768px){
			table th{width:auto!important}
		}
	}
}
.table.type-row{
	tbody tr:first-child>*{
		border-top:1px solid #707685;
	}
	tbody{
		tr>td{}
		tr>td:first-child{border-left:1px solid $bdcolor}
		tr>td:last-child{border-right:none;}
	}
}
.table-rtdeco{
	text-align:right;
	margin-top:-1.5em;
}
.table-rtdeco2{
	margin-top:-5px;
	margin-bottom:10px;
	@include breakpoint($md){
	text-align:right;
	margin-top:-44px;
	margin-bottom:15px-9;
	}
}
.table-rtdeco3{
	position:relative;
	@include breakpoint($md){
		a{
			position:absolute;
			right:0;
			bottom:0;
		}
	}
}
.view-movie{
	.set{
		overflow:hidden;
		padding:16px 0;
		border-top:1px solid #707686;
		border-bottom:1px solid #707686;
	}
	.subject{
		font-size:16px;
		float:left;
		font-family:'Noto-Regular';
		color:#222;
	}
	.fav{
		font-size:16px;
		float:right;
		span{vertical-align:top;}
	}
	.movieplayer{
		clear:both;
		text-align:center;
		padding:20px 0 12px;
	}
	.divboth .fl{padding-top:5px;}
	a.b-blue{
		padding:8px 25px !important;
	}
	.movie-txt{
		margin-top:12px;
		border-width: 1px;
		border-color: rgb(209, 209, 210);
		border-style: solid;
		background-color: rgb(248, 248, 249);
		padding:10px;
	}
	@include breakpoint($md){
		.b{
			position:relative;
			padding-right:329px;
			min-height:464px;
			.r{
				position:absolute;
				right:0;
				top:20px;
				// padding-left:25px;
				width:304px;
				.movie-txt{
					height:405px;
					overflow-y:auto;
				}
			}
		}
	}
}
.movie-list{
	ul{}
	li{}
	li+li{margin-top:20px;}
	.img{
		position:relative;
		padding-top:56%;
		overflow:hidden;
		display:block;
		img{
			position:absolute;
			left:0;
			width:100%;
			top:0;
			vertical-align:top;
		}
	}
	a{
		display:block;
		.played{
			position: absolute;
			left: 50%;
			top: 50%;
			margin-top: -28px;
			margin-left: -28px;
			background: url(/static/kspo/img/submain/submain6-play@2x.png) center center no-repeat;
			background-size: 56px;
			width: 56px;
			height: 56px;
			z-index: 1;
		}
	}
	span{
		display:block;
	}
	.set{
		border:1px solid $bdcolor;
		padding:12.5px 18.5px;
	}
	.txt{
		font-size:16px;
		border-bottom:1px dotted #b9b9b9;
		padding-bottom:18px;
		margin-bottom:18px;
	}
	.date{}
	@include breakpoint($md){
		border-bottom:1px solid #707686;
		padding-bottom:30px;
		margin-top:-40px;
		ul{
			overflow:hidden;
			margin:0 -16px;
		}
		li{
			float:left;
			width:33.33%;
			padding:0 16px;
			&:nth-child(3n+1){
				clear:both;
			}
		}
		li,
		li+li{margin-top:40px;}
		.img{
			img{
				width:100%;
			}
		}
		a{
			display:block;
		}
		span{
			display:block;
		}
		.set{
			border:1px solid $bdcolor;
			padding:12.5px 18.5px;
		}
		.txt{
			font-size:16px;
			border-bottom:1px dotted #b9b9b9;
			padding-bottom:18px;
			margin-bottom:18px;
			height:67px;
			overflow:hidden;
		}
		.date{}
	}
}
.tableT{
		margin-left:-1px;
		margin-right:-1px;
		width:100%;border-bottom:1px solid #707685;
		table-layout:fixed;
		th,
		td{
			border:1px solid $bdcolor;
		}
		th{
			font-size:15px;font-weight:normal;color:#222;padding:12px 0;border-top:1px solid #707685;font-family:'Noto-Regular';
		}
		td{
			font-size:15px;
			text-align:center;font-family: "Noto-Light", sans-serif;
			color:#656565;
			padding:10px 0;
			border-left:1px solid #e6e6e6;
			border-top:1px solid #e6e6e6;
			&.tal{
				padding-left:20px;
			}
		}
		tbody{
			th{font-family: "Noto-Light", sans-serif;border-top:1px solid #e6e6e6;}
			tr>td:first-child{
				// border-left:none;
			}
			.foot{
				th,
				td{
					background-color:#f8f8f9;
					color:#0a2a70;
					font-family:'Noto-Regular';
					text-align:center !important;
				}
			}
		}
}
