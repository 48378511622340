a{color:#565656;}
*{-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
        box-sizing: border-box;}
body{
	font-family:'Nanum Square';
	// font-family:'noto Sans';
	font-family:'맑은 고딕';
	color:#242424;
	font-size:14px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
#content img{max-width: 100%;}
#visual{
	height:250px;
	padding-top:97px;
	background:#fff url(/static/ucms/img/00_UCMS_SUB_VISUAL_1.jpg) center 0 no-repeat;
	border-top:1px solid #e3e3e3;
	text-align:center;
	line-height:1;
	color:#163d40;
	font-size:38px;
	@include breakpoint($sm){ 
		font-size: 22px; 
		height: 102px;
		background-size: 120%;
		padding-top: 15px;
	}
	font-family:'Nanum Square';
	font-weight:bold;
	position:relative;
	z-index:1;
	&.i300001{background-image:url(/static/ucms/img/00_UCMS_SUB_VISUAL_1.jpg)}
	&.i300003{background-image:url(/static/ucms/img/00_UCMS_SUB_VISUAL_2.jpg)}
	&.i300005{background-image:url(/static/ucms/img/00_UCMS_SUB_VISUAL_3.jpg)}
	&.i300007{background-image:url(/static/ucms/img/00_UCMS_SUB_VISUAL_4.jpg)}
	&.i300018{background-image:url(/static/ucms/img/00_UCMS_SUB_VISUAL_5.jpg)}
	&.i300022{background-image:url(/static/ucms/img/00_UCMS_SUB_VISUAL_6.jpg)}
	&:before{
		content: '';
		position:absolute;
		width:60px;
		height:3px;
		background-color:#163d40;
		top:68px;
		left:0;right:0;
		margin: 0 auto;
		@include breakpoint($sm){ top: 5px; }
	}
}
.rocation{
	border-bottom:1px solid #e3e3e3;
	.set{
		width:1000px;
		margin: 0 auto;
		height:48px;
	}
	nav{
		position:relative;
		.home{
			float:left;
			width:51px;
			height:48px;
			background:#fff url(/static/ucms/img/home.png) 50% 50% no-repeat;
			border:1px solid #e3e3e3;
			border-width:0 1px;
		}
		.depth1{
			float:left;
			position:relative;
			height:48px;
			z-index:2;
		}
		.depth1,
		.depth1-more{
			width:199px;
			line-height:48px;
			background:#fff url(/static/ucms/img/arr-down.png) 164px 50% no-repeat;
			border-right:1px solid #e3e3e3;
			padding-left:19px;
			font-size:18px;
			font-weight:bold;
		}
		.depth1-more{
			position:absolute;
			z-index:1;
			top:-80px;
			left:50px;
			width:200px;
			border:1px solid #e3e3e3;
			border-bottom:none;
			padding-left:0;
			background:#fff none;
			opacity:0;
			z-index:-1;
			// display:none;
			a{
				border-bottom:1px solid #e3e3e3;
				padding-left:19px;
				display:block;
			}
			&,
			*{
		        transition: all .55s ease-out;
			}
		}
		.depth2{
			@extend .depth1;
		}
		.depth2-more{
			left:249px;
			@extend .depth1-more;
		}
		.active{
			top:48px;
			opacity:1;
			z-index:1;
			display:block;
		}
	}
	.r{
		float:right;
		a{
			float:left;
			width:51px;
			height:48px;
			border:1px solid #e3e3e3;
			border-width:0 1px;
			border-right-width:0;
			text-align:center;
			// img{vertical-align:middle;}
			background:#fff url(/static/ucms/img/email.png) 50% 50% no-repeat;
			position:relative;
			span{display:none;}
			&:hover,
			&:focus {
				span{
					display:block;
					padding:12px 18px;
					border-radius:5px;
					background-color:#ffa900;
					position:absolute;
					right:0;
					top:66px;
					white-space:nowrap;
					color:#fff;
					&:before {
						content: '';
						position:absolute;
						right:10px;
						top:-7px;
						width:0;
						height:0;
						border-left:6px solid transparent;
						border-right:6px solid transparent;
						border-bottom:7px solid #ffa900;
					}
				}
			}
		}
		a+a{background-image : url(/static/ucms/img/doc.png); border-right-width:1px;}
	}
}
#container{
	width:1000px;
	margin: 0 auto;
	@include breakpoint($tb){ width:96%;margin-left: 2%;}
}
#container .hgroup{
	h1{
		font-size:30px;
		line-height:1;
		color:#163d40;
		padding-left:26px;
		background:#fff url(/static/ucms/img/bu1.png) 0 50% no-repeat;
		margin:60px 0 18px;
		@include breakpoint($xs){margin:30px 0 7px; font-size: 20px; }
		font-family:'Nanum Square';
		font-weight:bold;
	}
}
#container main{}
#content p{
	line-height: 1.8em;
}

.bu3{font-size:13px;position:relative;padding-left:9px;}
.bu3:after {
	position:absolute;left:0;top:8px;
	content: '';
	background-color:#a8a8a8;
	width:3px;height:3px;
}
.join1{margin: 30px 0 50px 17px}
.join1 .btn{}
.join1 .btn ul{overflow:hidden;}
.join1 .btn ul li{position: relative;float:left;width: 163px;padding: 0 11px 0 10px;background: url(/ucms/images/join/0100_vline.gif) repeat-y right 0;}
.join1 .btn ul li a{display:block;padding: 116px 0 0 0;background: url(/ucms/images/join/0100_btn1.gif) no-repeat 0 0;text-align: center;cursor: pointer;zoom:1; }
.join1 .btn ul li a.ico_2{background-image: url(/ucms/images/join/0100_btn2.gif);}
.join1 .btn ul li a.ico_3{background-image: url(/ucms/images/join/0100_btn3.gif);}
.join1 .btn ul li a.ico_4{background-image: url(/ucms/images/join/0100_btn4.gif);}
.join1 .btn ul li a .ico_chk{display: none; width: 36px;height: 28px;position: absolute;left:10px;top:0;background:#0089cc url(/ucms/images/join/0100_chk.png) no-repeat 0 0;}
.join1 .btn ul li a .txt{display:block;margin-bottom: 18px;font-size: 11px;}
.join1 .btn ul li a .t{display:block;font-weight: bold;color: #0089cc;zoom:1; }
.join1 .btn ul li a:focus .ico_chk,
.join1 .btn ul li a:hover .ico_chk{display:block;}
.join1 .btn ul li a:hover.ico_1{}
.join1 .btn ul li a.ico_2 .ico_chk{background-color: #419f02;}
.join1 .btn ul li a.ico_3 .ico_chk{background-color: #f56e00;}
.join1 .btn ul li a.ico_4 .ico_chk{background-color: #505050;}
.join1 .btn ul li a.ico_1 .t{}
.join1 .btn ul li a.ico_2 .t{color: #4b9719;}
.join1 .btn ul li a.ico_3 .t{color: #cc5802;}
.join1 .btn ul li a.ico_4 .t{color: #474747;padding: 9px 0 27px;}
.join1 .btn ul li a.ico_1 .t{}
.join1 .btn ul li a.ico_2 .t{}
.join1 .btn ul li a.ico_3 .t{}
.join1 .btn ul li a.ico_4 .t{}
.join1 .btn ul li.last{background-image:none;padding-right: 0px;}
.join-box{}
.join-box dt{margin-bottom: 12px;}
.join-box li{margin: 4px 0;}

.w30{width: 30px;}
.w20{width: 20px;}
.w50{width: 50px;}
.join3 .form_set{}
.join3 .form_set .tac{margin-bottom: 10px;}
.join3 .form_set dl{float:none;width:auto;border-top: 2px solid #0089cc;border-bottom: 1px solid #0089cc;overflow:hidden;line-height: 34px;margin-bottom: 20px;}
.join3 .form_set dl dt{border:none;float:left;width: 125px;border-right: 1px solid #d4d4d4;border-bottom: 1px solid #d4d4d4;height: 34px;}
.join3 .form_set dl dd{border:none;float:left;width: 197px;padding: 0 0 0 11px;height: auto;border-bottom: 1px solid #d4d4d4;height: 34px;}
.join3 .form_set dl dt label{padding-left: 23px;background: url(/ucms/images/common/list_style4_bu.gif) no-repeat 11px 8px;}
.join3{overflow:hidden;}
.join3 dl{float:left;width: 367px;}
.join3 dl dt{border:1px solid #91b5c6;background-color: #deebf1;border-bottom: 0;}
.join3 dl dt .l,
.join3 dl dt a{display:block;overflow:hidden;padding: 14px 12px 12px 9px;}
.join3 dl dt a{cursor: pointer;}
.join3 dl dt .bu{padding-left: 17px;background: url(/ucms/images/join/0102_bu.gif) no-repeat 0 center;}
.join3 dl dt a .fl{padding-left: 17px;background: url(/ucms/images/join/0102_bu.gif) no-repeat 0 center;}
.join3 dl dt a .fr{}
.join3 dl dd{padding: 38px 15px 0;height:240px;border:1px solid #d7d7d7;}
.join3 dl dd .i{padding-bottom: 30px;text-align: center;}
.join3 dl dd img{}
.join3 dl.r dt{border-left: 0;}
.join3 dl.r dd{border-left: 0;}
.join3_compay dl dd{height: 355px;}
/* 필수입력 */
.req{display:inline-block;width:15px;height:15px;}
.req:before {
	content: '';
	font:normal normal normal 14px/1 FontAwesome;content:"\f069";color:#df8800;
}
.req>*{position:absolute;width:1px;height:1px;margin:-1px;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0}

.ico_rq{background: url(/ucms/images/join/ico_rq.gif) no-repeat 0 center;text-indent: -999em;width: 13px;height: 22px;display:inline-block;vertical-align: middle;text-align: left;}
.ico_point{background: url(/ucms/images/common/list_style4_spot.gif) no-repeat 0 center;text-indent: -999em;width: 10px;height: 22px;display:inline-block;vertical-align: middle;text-align: left;}
.join4{}
.join4 .t{margin: 50px 0 30px;text-align: center;}
.join4 .coninforbox{padding: 15px 18px;margin-bottom: 20px;}
.join4 .coninforbox .fl{}
.join4 .coninforbox .fr{width: 260px;height: 31px;background: url(/ucms/images/join/0104_box.gif) no-repeat 0 0;}
.join4 .coninforbox .fr dl{overflow:hidden;padding: 7px 14px;font-weight: bold;font-size: 14px;}
.join4 .coninforbox .fr dl dt{float:left;padding: 0 12px 0 15px;background: url(/ucms/images/common/listType_bu1.gif) no-repeat 0 center;}
.join4 .coninforbox .fr dl dd{color: #e73100;}
.join4 .tac{margin: 65px 0 0 0;}
.blueblock_txt{background-color: #2574b6;font-weight: bold;color: #fff;padding: 4px 2px;}
.plist{
	margin:30px 0 45px;
	li,
	p{
		font-size:18px;
		padding-left:24px;
		position:relative;
		line-height:1.67;
		&:before {
			content: '';
			display:inline-block;
			width:4px;
			height:4px;
			background-color:#163d40;
			vertical-align:middle;
			position:absolute;
			left:0;
			top:0.55em;
		}
	}
}
.bu1{
	font-size:30px;
	color:#163d40;
	padding-left:26px;
	background:#fff url(/static/ucms/img/bu1.png) 0 50% no-repeat;
	line-height:1;
	margin:60px 0 20px;
	font-family:'Nanum Square';
	font-weight:bold;
}
@mixin bu2() {
	position:relative;
	padding-left:14px;
	&:before {
		content: '';
		display:inline-block;
		width:4px;
		height:4px;
		background-color:#163d40;
		vertical-align:middle;
		position:absolute;
		left:0;
		top:9px;
	}
}
.bu2{
	margin:40px 0 20px;
	font-size:20px;
	line-height:1;
	font-weight:bold;
	@include bu2;
	
}
.bu-dot{
	@extend .bu2;
	margin:5px 0;
	font-size:1em;
	font-weight:normal;
	&:before {top:5px}
}

.bu2set{
	.bu2{margin-bottom:10px;}
	p{
		margin-left:$m1;
		@extend .txt1;
	}
}

.box{border:1px solid #ccc;padding:8px;text-align:center;text-align:center;}
.box .s{background-color:#f5f5f5;padding:35px 0 25px;margin-bottom:8px;}
.box .s p.t{font-size:25px;color:#df8800;}
.box .s p.t+p{font-size:25px;color:#949494;font-size:14px;}
.fieldW{position:relative;padding-left:50px;display:block;}
.fieldW span{position:absolute;left:0;top:0;width:50px;line-height:30px;}
.fieldW span+*{width:100%;}
.fieldemail{position:relative;padding-left:50px;display:block;overflow:hidden;}
.fieldemail span{position:absolute;left:0;top:0;width:50px;}
.fieldemail *{float:left;}
.fieldemail input{width:45%;}
.fieldemail i{width:10%;text-align:center;display:inline-block;font-style:normal;line-height:30px;}
.fieldemail select{width:100%;margin-top:5px;height:30px;}
/**/
.sm{
	>ul{margin:0 -10px;overflow:hidden;}
	>ul>li{float:left;width:33.333%;padding:10px;text-align:center;
		@include breakpoint($xs){ width: 50%; }
	}
	>ul>li .set{
		// height:402px;
		border:5px solid $bdcolor;background:#fff url(/static/ucms/img/patten.png) 0 0 repeat;
		&:before {
			content: '';
			width:96px;
			height:96px;
			background:#d0d8d9 url(/static/ucms/img/ico-sm1.png) center center no-repeat;
			border-radius:50px;
			display:block;
			margin: 30px auto 40px;
			@include breakpoint($xs){ width: 40px; height: 40px;background-size: 60%; margin:5px auto 25px; }
		}
	}
	>ul>li:nth-child(2) .set:before{background-image:url(/static/ucms/img/ico-sm2.png);}
	>ul>li:nth-child(3) .set:before{background-image:url(/static/ucms/img/ico-sm3.png);}
	>ul>li:nth-child(4) .set:before{background-image:url(/static/ucms/img/ico-sm4.png);}
	>ul>li:nth-child(5) .set:before{background-image:url(/static/ucms/img/ico-sm5.png);}
	>ul>li:nth-child(6) .set:before{background-image:url(/static/ucms/img/ico-sm6.png);}
	>ul>li .num{display:block;font-size:18px;color:#df6900;position:relative;margin:15px 0;display:none;}
	>ul>li .num:after {
		content: '';
		width:16px;
		border-bottom:2px solid #df6900;
		position:absolute;
		bottom:0;left:50%;margin-left:-8px;
	}
	>ul>li .title{
		font-size:22px;font-family:'Nanum Square';font-weight:bold;
		position:relative;
		margin-bottom:13px;
		display:block;
		&:before {
			content: '';
			position:absolute;
			width:60px;
			height:2px;
			background-color:#163d40;
			left:0;
			right:0;
			margin: 0 auto;
			top:-18px;
			
		}
	}
	>ul>li ul{padding-bottom:10px;}
	>ul>li ul li{}
	>ul>li ul li a{display:block;font-size:18px;padding:5px 0;color:#565656;
		@include breakpoint($xs){ font-size: 16px; }
	}
	>ul>li ul li a:hover,
	>ul>li ul li a:focus{color:#df8800;}
}

.sh{
	text-align:right;
	margin-top:-30px;line-height:30px;
	@include breakpoint($sm){ margin-top: 10px; }
}

.partner{
	display: block;
	width: 100%;
	margin:0 -10px;
	@include breakpoint($sm){ margin: 0; }
	overflow:hidden;
	li{
		float: left;
		padding:10px;
		width:25%;
		@include breakpoint($sm){ width: 50%; padding: 4px; }
		&:nth-child(4n){
			margin-right: 0;
		}
		img{
			width:100%;
			border:1px solid #cacaca;
		}
	}
}
.ico-download{background:url(/static/ucms/img/ico-download.png) right center no-repeat;padding-right:33px;height:21px;display:inline-block;}
td .ico-download{padding-right:23px;}
/*FAQ*/
#faqList{
	position:relative; margin:40px 0 0; border-top:3px solid #565656; border-bottom:1px solid #707580;
	dt,
	dd {position:relative;padding:10px 10px 12px 110px;border-bottom:1px solid #d6d6d6;}
	dt{
		line-height:37px;
		padding-top:15px;
		padding-bottom:15px;
		background:#fff url(/static/ucms/img/arr-down.png) 956px 50% no-repeat;

		&:hover{
		// background-color:#e9e9e9;
		// background: linear-gradient(180deg, #FFFFFF 35%, #e9e9e9 100%);
			button{color:#163d40;text-decoration:underline;}
		}
		button{
				display:block;
				line-height:18px;
				background-color: transparent;
				border: 0;
				width: 100%;
				text-align: left;
				font-size:16px;
				color:#163d40;
				font-weight:bold;
			}
	}
	dd {background:#f8f8f8;min-height: 67px;padding-top:27px;padding-bottom:27px;line-height:1.87;}
	.qna_img{position:absolute; left:0px; top:0;width:90px;height:51px;
		&:before {
			content: '';
			position:absolute;
			left:90px;
			top:17px;
			height:16px;
			border-right:1px solid #919191;
		}
	}
	.qna_img:after {
		content: 'Q';
		font-size:20px;
		font-weight:normal;
		line-height:51px;
		color:#163d40;
		text-align:center;
		width:90px;
		display:block;
		font-weight:bold;
	}
	dd .qna_img:before{top:30px;}
	dd .qna_img:after {
		content: 'A';
		font-size:20px;
		background-color:#fff;
		border-radius:100px;
		width:40px;height:40px;
		line-height:40px;
		top:19px;
		left:25px;
		position:absolute;
		font-weight:bold;
	}
	.no-data {width:100%; text-align:center; padding-left:0; background-image:none; font-weight:bold;}
}
.c0101Top{padding:0 0 45px 0;background:url(/static/ucms/img/01_01_topbg.png) right bottom no-repeat;color:#565656;line-height:1.92;}
.c0101Top dl{width:380px;overflow:hidden;font-size:13px;}
.c0101Top dl dt{float:left;width:50px;font-weight:normal;line-height:1.92;}
.c0101Top dl dd{float:left;width:330px;line-height:1.92;}
.adm{margin-top:50px;border-top-width:1px;border-bottom-color:$bdcolor;}
.adm>dl>dt{
	width:195px;
	text-align:center;padding-left:0;font-weight:bold;
	@include breakpoint($tb){ 
		width: 20%;
	}
}
.adm>dl>dd{}
.adm input[type=text]{text-indent:10px;height:40px;width:100% !important;}
.adm .b-submit{height:40px;width:100%;}
.adm p{font-size:13px;}
.adm label{
	max-width: inherit;margin-right:25px;
	@include breakpoint($xs){ 
		display: block;
		clear: both;
	}
}
/**/
.box1{border:3px solid #febe10;padding:15px 30px;}
.blackBox{display: inline-block;padding:2px 10px;margin-bottom: 6px;background:#322f31;color:#fff;-o-border-radius: 3px;-moz-border-radius: 3px;-webkit-border-radius: 3px;
border-radius: 3px;}
.yellowBox{display: inline-block;padding:2px 10px;margin-bottom: 6px;background:#febe10;-o-border-radius: 3px;-moz-border-radius: 3px;-webkit-border-radius: 3px;
border-radius: 3px;}
.box1 .spacer{margin-left: 66px;}
/* 댓글 */
.vline {color:#ccc;margin:0 3px}
.recomentList{
	overflow:hidden;
	.set{
		overflow:hidden;
		width:100%;
		padding:20px 116+30px 20px 20px;
		position:relative;
		border-bottom:1px solid $bdcolor;
	}
	.set .i{}
	.set .characterLen {height:40px;display:block;width:100%;padding:10px;border:1px solid #c9c9c9}
	.set .b-submit {height:40px;overflow:hidden;width:116px;position:absolute;right:20px;top:20px;}
	.set .characterLenDeco {
		float:right;
		font-size:13px;
		padding:5px 0;
	}
	li {overflow:hidden;position:relative;width:100%;padding:15px 0;border-bottom:1px solid #ccc}
	li .cmtBody {margin:0 150px 0 0}
	li .infoAppend {display:block;height:20px}
	li .name {font-weight:bold;color:#888}
	li .time {color:#b1b1b1}
	li .txtDesc {font-size:12px;line-height:18px}
	li .cmtFoot {overflow:hidden;position:absolute;top:15px;right:0;height:40px;font-size:11px}
}

.paginationSet{text-align:center;}
.paginationSet+.btnSet{
	// margin-top:-20px-38;
	margin-top:0;
}
#popWrap{
	background-color:#fff;
	padding:30px;
	.bu1{
		margin:0 0 30px;
	}

}
.errimg{
	background:url(/static/bos/img/126X118.gif) center center no-repeat;
	width:126px;
	height:118px;
	display:inline-block;
}
.emailId{width:120px;}
.emailAddr{width:120px;}
.infor{
	font-size:20px;
	line-height:1.6;
	font-family:'Nanum Square';
	font-weight:bold;
	margin-bottom:30px;
	letter-spacing:-0.5px;
	@include breakpoint($sm){ font-size: 18px; }
}
.certifi-list{
	overflow:hidden;
	img{
		float:left;margin-right:20px;
		@include breakpoint($tb){ width: 46%;margin:20px 2%; }
		&:last-child{margin-right:0;}
	}
}
.c300002{
	overflow:hidden;
	dl{
		float:left;
		// width:50%;
		width:542px;
		padding-left:130px;
		height:200px;
		@include breakpoint($sm){  padding-left:40px; width: 100%; height: auto; margin-bottom: 20px; }
		position:relative;
		letter-spacing:-0.04em;
		&:after {
			content: '';
			position:absolute;
			width:60px;
			height:2px;
			background-color:#163d40;
			left:157px;
			top:0;
			@include breakpoint($sm){  left: 40px; }
		}
		&:before {
			content: '';
			width:100px;
			height:100px;
			position:absolute;
			left:0;
			top:32px;
			background: url(/static/ucms/img/300002_img1.png) center 0 no-repeat;
			@include breakpoint($sm){  background-size: 100%; width: 30px;height: 30px; }
		}
		&.i2{width:450px;}
		&.i2:before{background-image:url(/static/ucms/img/300002_img2.png);}
		&.i3:before{background-image:url(/static/ucms/img/300002_img3.png);}
		&.i4{width:450px;}
		&.i4:before{background-image:url(/static/ucms/img/300002_img4.png);}
		&.i5:before{background-image:url(/static/ucms/img/300002_img5.png);}
		@include breakpoint($sm){  &.i2, &.i4{width:100%;} }
	}
	dt{
		font-weight:bold;
		font-size:22px;
		padding:23px 0 20px;
		font-family:'Nanum Square';
		letter-spacing:-0.5px;
		@include breakpoint($sm){  font-size:18px; }
	}
	dd{
		font-size:14px;
		line-height:1.67;
		letter-spacing:-0.5px;
		@include breakpoint($sm){  font-size:13px; }
	}
}
.c300016{
	@extend .c300002;
	dl{width:50% !important;
		@include breakpoint($sm){ width: 100% !important; }
	}
}
.boxinfor{
	margin:25px 0;
	border:4px solid #d0d8d9;
	padding:68px 0 45px 176px;
	@include breakpoint($sm){ padding-left: 60px; padding-top: 30px; }
	font-size:20px;
	letter-spacing:-0.5px;
	font-weight:bold;
	background:#fff url(/static/ucms/img/patten.png) 0 0 repeat;
	position:relative;
	font-family:'Nanum Square';
	color:#163d40;
	&:after{
		content: '';width:60px;height:2px;background-color:#163d40;position:absolute;left:176px;top:45px;
		@include breakpoint($sm){ left: 60px; top: 27px; }

	}
	&:before {
		content: '';
		position:absolute;
		left:40px;
		top:18px;
		background: url(/static/ucms/img/ico-300011.png) 0 0 no-repeat;
		width:96px;
		height:96px;
		@include breakpoint($sm){ width: 40px;height: 40px; background-size: 100%; left: 15px; top: 50%; margin-top: -20px;}
	}
}
.txt1{
	font-size:20px;
	font-family:'Nanum Square';
	font-weight:bold;
	line-height:1.6;
	letter-spacing:-0.5px;
}

.c300088{
	img{margin:20px 0;}
}

.list2{
	font-size:15px;
	line-height:1.67;
	overflow:hidden;
	li{
		float:left;width:50%;
		position:relative;
		padding-left:14px;
		&:before {
			content: '';
			display:inline-block;
			width:4px;
			height:4px;
			background-color:#163d40;
			vertical-align:middle;
			position:absolute;
			left:0;
			top:9px;
			
		}
	}
}

.ptbox{
	padding:50px 40px 50-14px;
	@include breakpoint($sm){ padding:20px 15px 20-7px; }
	border:5px solid #d0d8d9;
	background:#fff url(/static/ucms/img/patten.png);
	overflow:hidden;
	font-family:'Nanum Square';
	letter-spacing:-0.5px;
	dl{
		float:left;
		width:50%;
		padding:0 0 0 125px;
		margin-bottom:14px;
		@include breakpoint($sm){ width: 100%; padding-left: 75px; }
	}
	dt{
		position:relative;
		font-size:22px;font-weight:bold;
		padding-top:32px;
		line-height:1;
		margin-bottom:12px;
		&:after {
			content: '';
			position:absolute;
			width:60px;
			height:2px;
			background-color:#163d40;
			left:0;
			top:10px;
		}
		&:before {
			content: '';
			position:absolute;
			left:-125px;
			top:0;
			width:96px;
			height:96px;
			background: url(/static/ucms/img/ico-1.png) 0 0 no-repeat;
			@include breakpoint($sm){  background-size: cover; width: 50px;height: 50px; left: -75px; }
		}
	}
	dl.i2 dt:before{background-image:url(/static/ucms/img/ico-2.png);}
	dl.i3 dt:before{background-image:url(/static/ucms/img/ico-3.png);}
	dl.i4 dt:before{background-image:url(/static/ucms/img/ico-4.png);}
	dd{font-size:18px;}
}

.pwCheck{
	text-align:center;
	p{
		background: url(/static/ucms/img/ico-pwcheck.png) center 0 no-repeat;
		padding-top:137px;
		font-family:'Nanum Square';
		font-size:22px;
		font-weight:bold;
		letter-spacing:-1px;
		margin-bottom:50px;
		position:relative;
		&:after {
			content: '';
			position:absolute;
			width:60px;
			height:2px;
			background-color:#163d40;
			left:0;
			right:0;
			top:117px;
			margin: 0 auto;
		}
	}
	.title{
		@include bu2;
		font-size:20px;
		font-family:'Nanum Square';
		font-weight:bold;
		padding-right:20px;
	}
	input[type=password]{
		width:320px;
		height:50px;
	}
	input[type=submit]{margin-left:10px;}
}
.join4{
	@extend .pwCheck;
	p{margin-bottom:0;}
	p.t2{
		&:after{display:none;}
		color:#242424;
		padding:0;
		background:none;
		margin:10px 0;
		font-size:18px;
	}
}
.secede{
	@extend .pwCheck;
	margin:50px 0;
	p{background-image:url(/static/ucms/img/ico-secede.png);}
	label{
		display:block;
		width:724px;
		margin: 0 auto;
		text-align:left;
	}
	.title{
		width:112px;
		display:inline-block;
		&+span{line-height:50px;font-size:18px;font-weight:bold;}
	}
	
}
.pwchange{
	@extend .pwCheck;
	label{display:block;}
	label+label{margin-top:10px;}
	.tac{margin-top:50px;}
}
.map{
	margin-top:20px;
	dl{
		float:none;
		width:auto;
	}
	dl+dl{margin-top:30px;}
	dl.i1 dt:before{background-image:url(/static/ucms/img/ico-bus.png);}
	dl.i2 dt:before{background-image:url(/static/ucms/img/ico-subway.png);}
	dl.i3 dt:before{background-image:url(/static/ucms/img/ico-minibus.png);}
	dd{line-height:1.67;}
}