/* 테이블 */
.bdList{
	margin-top:13px;
	border-top:2px solid $bdtopcolor;
	width: 100%;
	overflow-x:scroll; 
	table{
		width:100%;
		table-layout:fixed;
	}
	th,
	td{text-align:center; font-size:15px; color:#777;}
	// th:first-child,
	// td:first-child {padding-left:20px; }
	thead{
		th{
			height:50px; 
			font-size:16px;
			color:#163d40;
			font-weight:bold;
			border-bottom:1px solid $bdcolor;
			position:relative;
			&:before {
				content: '';
				position:absolute;
				left:0;
				top:50%;
				margin-top:-8px;
				height:16px;
				border-left:1px solid $bdcolor;
			}
			&:first-child:before{display:none;}
			&.no{width:80px;}
			&.file{width:80px;}
			&.name{width:100px;}
			&.date{width:130px;}
			&.count{width:80px;}
		}
	}
	tbody{
		th,
		td{
			font-family:'Noto-Light';
			font-weight:normal;
			border-bottom:1px solid $bdcolor;
			@include breakpoint($md){
				height:44px;
			}
		}
		th{
			color:#222;
		}
	}
	td a {color:#666; line-height:1.2;}
	.notice td {font-size:16px; color:#0a2a70; border-bottom:1px solid $bdtopcolor; background:#f2f5fb;}
	.tit,
	.title {text-align:left;
		@include breakpoint($sm){ width: 100%; display: block; font-weight: bold; }
	}
	.tit a{
		display:block;
		text-overflow:ellipsis;overflow:hidden;white-space:nowrap;
		line-height:1.47;
	}
	.num {}
	a[target="_blank"]:after{
		content: '';
		display:inline-block;
		margin-left:10px;
		background: url(/static/kspo/img/board/sp-icon.png) -113px -24px no-repeat;
		width:19px;
		height:16px;
	}
	@include breakpoint(max-width 767px){
		td.pc{display:none;}
		table{display:block;}
		thead{display:none;}
		tbody{display:block;}
		tr{
			position:relative;
			display:block;
			padding:20px 0 20px 44px;
			border-bottom:1px solid #e6e6e6;
			line-height:2.07;
			&.nodata,
			&.notice{
				padding:10px;
				background:#f2f5fb;
				td{
					padding:0 10px 0 0;
					border:none;
					&.num{
						position:static;
					}
				}
			}
		}
		&.no-num tr{padding-left:0;}
		tbody th,
		tbody td{
			display:inline-block;
			border:none;
			padding:0 10px 0 0;
			&:first-child{padding-left:0;}
			&.num{
				position:absolute;
				left:5px;
				top:20px;
				padding:0;
			}
			&.title{display:block;padding:0;
				a{text-overflow:ellipsis;overflow:hidden;white-space:nowrap;display:block;
					// &:before{
					// 	content: '';
					// 	width:100%;
					// 	height:100%;
					// 	position:absolute;
					// 	left:0;top:0;
					// }
				}
			}
			&.btn,
			&.m-block{
				display:block;
				text-align:left;
			}
			&.mc{
				color:#222;
				font-family:'Noto-Regular';
			}
		}
		&.type2{
			tr{
				padding:10px 0;
			}
			th,td{display:block;text-align:left;padding:0;}
			th{
				font-size:16px;
				font-family:'Noto-Regular';
				color:#222;
				// margin-bottom:10px;
			}
			td{
				// margin-bottom:6px;
				&:last-child{margin-bottom:0;}
			}
		}
	}
	@include breakpoint($md){
		.fix-notice td {font-size:16px; color:#0a2a70; border-bottom:1px solid $bdtopcolor; background:#f2f5fb;}
		&.bd td{
			border-left:1px solid #e6e6e6;
			&:first-child{border-left:none;}
		}
		td.btn>*{width:100%;min-width:0;}
	}
}
.totalCount{
	margin-top:40px;
	font-size:15px;
	font-weight:bold;
	line-height:1;
}