/*paging*/
$pagingActiveBg : transparent;
$pagingActiveBg : #feb424;
$pagingActiveColor : #fff;
$pagingbdcolor : transparent;
$pagingbgcolor : transparent;
$lmargin : -1px;
$width:40px;
$height:40px;

.paginationSet{
	text-align:center;
	margin-top:40px;
	// margin:0 50px;
}
.paging {text-align: center;margin-top:30px;}
.pagination em {font-style: normal}
.pagination{display:inline-block;padding-left:0;text-align:center;

}
.pagination>li{display:inline;}
.pagination>li>a,
.pagination>li>span{
	position:relative;
	float:left;
	padding:0;
	text-decoration:none;
	width:$width;
	height:$height;
	line-height:$height;
	font-size:14px;
	background-color:$pagingbgcolor;
	border:none;
	margin:0 $lmargin;
	color:#666;
	.gbox2 &{
	}
}
.pagination>li{
	&.first>*,
	&.prev>*,
	&.next>*,
	&.end>*{
		background-image:url(/static/ucms/img/paging.png);
		// background-size:80px 20px;
		border:1px solid #dee0e6;
		@include breakpoint($lg){
		// background: url(/static/kspo/img/common/pagingset.png) 0 0 no-repeat;
		}
		width:$width;
		// font-size:13px;
		// padding-left:29px;
		// text-align:left;
		&:before {content: '';display:inline-block;}
	}
	.s span{position: absolute;width: 1px;height: 1px;margin: -1px;padding: 0;overflow: hidden;clip: rect(0,0,0,0);border: 0}
}

.pagination>li.first>* 	{&:before {}}
.pagination>li.prev>* 	{
	background-position:-$height 0;
	margin-right:27px;
	&:before {}
}
.pagination>li.next>* 	{
	background-position:-508px 0;
	margin-left:27px;
	&:before {}
}
.pagination>li.end>* 	{
	background-position:right 0;
	&:before {}
}

.pagination>li .t{position: absolute;width: 1px;height: 1px;margin: -1px;padding: 0;overflow: hidden;clip: rect(0,0,0,0);border: 0}
.pagination>li:first-child>a,
.pagination>li:first-child>span {margin-left: 0;}
.pagination>li:last-child>a,
.pagination>li:last-child>span {}
.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
z-index: 2;
text-decoration:underline;
}
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
	color:$pagingActiveColor;
	background-color:$pagingActiveBg;
	z-index: 3;
	cursor: default;
	font-weight:normal;
	text-decoration:underline;
	// .gbox2 &{color:#000;}
}
.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {cursor: not-allowed;opacity:0.7;border-color:#d8d8d8;display:none;}
@include breakpoint(max-width 767px){
	.paginationSet > ul > li.i > a{
		width:$width/2;
		border:none;
	}
	.paginationSet > ul > li.i > a > span.s{
		position:absolute;width:1px;height:1px;margin:-1px;padding:0;overflow:hidden;clip:rect(0,0,0,0);
		border:0
	}
	// .pagination > li.next > *{background-position:-580px 0;}
}
@include breakpoint(max-width 480px){
	.paginationSet{margin-left:-15px;margin-right:-15px;}
	.paginationSet > ul > li.i > a{}
	// .pagination > li > a, .pagination > li > span{padding:0 3px;}
	.pagination > li.prev > *{margin-right:0;}
	.pagination > li.next > *{margin-left:0;}
}